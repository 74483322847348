import React from 'react';

function ServicesCard(props) {
    return (
        <>
            <div
                id="aboutContent"
                className="flex-col md:flex-row"
                style={{
                    display: "flex",
                    justifyContent:"space-around",
                    marginRight: "auto",
                    marginLeft: "auto",
                    alignItems:"center",
                    padding:"1rem",
                    width:"100%",
                }}
            >
                <div className="service-card-overlap md:overlap">
                    <div
                        className=""
                        style={{
                            display:"flex",
                            alignItems:"center",
                            color: "hsl(229,29%,86%)",
                            backgroundImage: "linear-gradient(to right ,#583493 ,  #90346D)",
                            padding:"0 0.5rem 0 0.5rem",
                            borderRadius: "35px",
                        }}
                    ><img style={{width:"110px", padding:"16px"}} src={props.titleIcon} alt=""/>
                        <h2>
                            <b
                                style={{
                                    color: "white",
                                }}
                            >
                                {props.title}
                            </b>
                        </h2>
                    </div>
                </div>
                <div style={{width:"100%",}} className="service-card-overlapped md:overlapped">
                    <div
                        className="service-card-padding md:service-card-content-padding"
                        style={{
                            color: "white",
                            backgroundColor: "#6B6A6A",
                        }}
                    >
                        <h2>
                            <b>
                                {props.title}
                            </b>
                        </h2>
                        <p>
                            {props.children}
                        </p>
                        <div style={{ width:"100%",
                            display:"flex",
                            justifyContent:"center",
                        }}>
                            <img style={{width:"60px", padding:"13px"}} src={props.smallIconOne} alt=""/>
                            <img style={{width:"60px", padding:"13px"}} src={props.smallIconTwo} alt=""/>
                            <img style={{width:"60px", padding:"13px"}} src={props.smallIconThree} alt=""/>
                            {props.smallIconFour && <img style={{width:"60px", padding:"13px"}} src={props.smallIconFour} alt=""/>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ServicesCard;