import React from 'react';
import images from "../../constants/images"


function Footer(props) {
    return (
        <div className="container top-pp"
             style={{
                 zIndex:"1",
                 marginRight: "auto",
                 marginLeft: "auto",
                 marginTop:"70vh",
                 boxShadow: "0 0 0 100vmax #ecdcff",
                 position:"sticky",
                 bottom:"35vh",
             }}
        >

            <div
                className="flex-col-reverse md:flex-row"
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-evenly",
                    backgroundColor: "hsl(257, 8%, 18%)",
                    color: "white",
                }}
            >
                <div
                >
                    <h1 >Let's discuss your next Project!</h1>
                    <div className="md:w-60%">
                        <p>Email: <a href = "mailto: g09.shah@gmail.com">g09.shah@gmail.com</a><br/>  or </p>
                        <a aria-label="Chat on WhatsApp" href="https://wa.me/447429566625" target="_blank" rel="noreferrer"> <img alt="Chat on WhatsApp" src={images.whatsAppButtonGreenSmall} />
                            </a>
                    </div>
                </div>
                <div
                    style={{
                        width:"50%",
                    }}
                >
                    <img src={images.nepaliBg} style={{width: "100%"}} alt=""/>
                </div>
            </div>
        </div>
    );
}

export default Footer;
