import React from 'react';
import images from "../../constants/images";

function Hero() {
    return (
        <div
            className="container full-bleed flex-col-reverse md:flex-row"
            style={{
                marginLeft: "auto",
                marginRight: "auto",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-evenly",
                padding: " 0 1.5rem 4rem",
                background: "#ecdcff",
                zIndex:"2",
                position:"relative",
            }}
        >
            <div className="md:w-40%">
                <h1 style={{color: "#00b4ff", textDecoration: "underline"}}>Depict your ideas into reality!</h1>
                <p style={{fontFamily:"fantasy", fontSize:"1.5em"}}>
                    Secure, Beautiful, Affordable, Bespoke software solutions with the latest technologies
                    that empowers your business.
                </p>
            </div>
            <div>
                <img src={images.heroIllustration} alt="hero illustration" style={{display: "block", width: "100%"}}/>
            </div>
        </div>
    );
}

export default Hero;
