import React from 'react';
import ServicesCard from "./ServicesCard/ServicesCard";
import images from "../../constants/images";

function Services() {
    return (
        <div className="container"
             style={{
                 marginLeft: "auto",
                 marginRight: "auto",
                 background: "white",
                 zIndex:"2",
                 position:"relative",
                 boxShadow: "0 0 0 100vmax white",
                 clipPath: "inset(0 -100vmax)",
             }}
        >
            <div>
                <h2>Our Services</h2>
                <ServicesCard title = "Software Development" titleIcon={images.apiIcon} smallIconOne ={images.javaIcon} smallIconTwo ={images.springBoot} smallIconThree ={images.awsIcon} smallIconFour={images.mySqlIcon}>
                 <p> Whether your want to solve a problem, better integrate your software or implement automation we’ve got you covered.</p>
                    Custom design your software and systems to optimise, automate and discover your unique strategy that meets your business and technology needs.
                </ServicesCard>
                <ServicesCard title="UI/UX & Graphic Design" titleIcon={images.uiUx} smallIconOne ={images.xdIcon} smallIconTwo ={images.figmaIcon} smallIconThree ={images.photoShopIcon}>
                    Our Creative team of designers offers a full range of web and mobile app design services,
                    from prototyping and graphic design to pixel-perfect UI
                </ServicesCard>
                <ServicesCard title = "Web Development" titleIcon={images.mobileAppIcon} smallIconOne ={images.flutterIcon} smallIconTwo ={images.androidIcon} smallIconThree ={images.appleIcon}>
                    We have expertise in developing Websites/Web applications using react
                </ServicesCard>
                <ServicesCard title = "Mobile App Development" titleIcon={images.mobileAppIcon} smallIconOne ={images.flutterIcon} smallIconTwo ={images.androidIcon} smallIconThree ={images.appleIcon}>
                We have expertise in developing mobile app for both IOS an Android platform using flutter
            </ServicesCard>
                <ServicesCard title = "Payment Integration" titleIcon={images.webAppIcon} smallIconOne ={images.reactIcon} smallIconTwo ={images.javaIcon} smallIconThree ={images.mySqlIcon}>
                    Whether you are looking to develop a eCommerce, CMS, website or web application, we can seamlessy integrate online Payment Options to you site.
                </ServicesCard>
            </div>
        </div>
    );
}

export default Services;
