import heroIllustration from '../assets/hero-illustration.svg'
import nepaliBg from '../assets/nepali-bg.svg'
import androidIcon from '../assets/icons/android.svg'
import appleIcon from '../assets/icons/apple.svg'
import figmaIcon from '../assets/icons/figma.svg'
import reactIcon from '../assets/icons/react.svg'
import webAppIcon from '../assets/icons/web-app.svg'
import mobileAppIcon from '../assets/icons/mobile-app.svg'
import uiUx from '../assets/icons/ui-ux.svg'
import apiIcon from '../assets/icons/api-icon.svg'
import photoShopIcon from '../assets/icons/photo-shop.svg'
import xdIcon from '../assets/icons/xd.svg'
import flutterIcon from '../assets/icons/flutter.svg'
import javaIcon from '../assets/icons/java.svg'
import mySqlIcon from '../assets/icons/mysql.svg'
import awsIcon from '../assets/icons/aws.svg'
import springBoot from '../assets/icons/spring-boot.svg'
import whatsAppButtonGreenLarge from '../assets/WhatsAppButtonGreenLarge.svg'
import whatsAppButtonGreenSmall from '../assets/WhatsAppButtonGreenSmall.svg'

const images = {
    heroIllustration,
    nepaliBg,
    androidIcon,
    appleIcon,
    figmaIcon,
    reactIcon,
    webAppIcon,
    mobileAppIcon,
    uiUx,
    apiIcon,
    photoShopIcon,
    xdIcon,
    flutterIcon,
    javaIcon,
    mySqlIcon,
    awsIcon,
    springBoot,
    whatsAppButtonGreenLarge,
    whatsAppButtonGreenSmall
}
export default images
