import Hero from "./container/HeroSection/Hero"
import Footer from "./container/Footer/Footer"
import Services from "./container/Services/Services"

function App() {
    return (
        <>
            <div style={{padding: "2rem", backgroundColor: "#ecdcff"}}>
                <h1
                    style={{
                        fontSize: "2rem",
                        fontWeight: "normal",
                        zIndex: "2",
                        position: "relative",
                    }}
                >rara-software</h1>
            </div>
            <Hero/>
            <Services/>
            {/*<Speaking/>*/}
            {/*<div style={{
                display:"block",
                height:"100vh",
            }}></div>*/}
            <Footer/>

        </>
    )
}

export default App
